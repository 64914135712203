




































import { Component, Vue } from "vue-property-decorator";
import { captureSentryError } from "@/plugins/sentry";
import { Action } from "vuex-class";
import { NotificationRequest } from "@/types/state";
@Component
export default class NotFound extends Vue {
  @Action("addNotification") addNotification!: (
    request: NotificationRequest
  ) => void;
  mounted(): void {
    captureSentryError(new Error("URL not found:" + window.location.href));
    this.addNotification({
      type: "info",
      message: `The requested URL: ${window.location.href} was not found.`,
    });
  }
}
