import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"px-5",attrs:{"cols":"12","offset-md":"3","offset-lg":"3","md":"6","lg":"6"}},[_c(VCard,[_c(VCardTitle,{staticClass:"just"},[_c('div',{staticClass:"mx-auto text-center text-break"},[_vm._v(" 404: Sorry we can't find the page you're looking for ")])]),_c(VCardText,[_c(VImg,{staticClass:"mx-auto",attrs:{"contain":"","max-width":"500","max-height":"500","src":require("@/assets/not-found.svg")}})],1),_c(VCardActions,[_c(VBtn,{staticClass:"font-weight-bold mx-auto mb-4",attrs:{"color":"lime darken-4","large":"","exact":"","block":_vm.$vuetify.breakpoint.mdAndDown,"to":{ name: _vm.$constants.ROUTE_NAMES.HOME }}},[_vm._v("Take Me Home")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }